import React, { Component } from 'react'
import Header from '../../components/header'
import {StyledCG, StyledButton} from "./StyledCG";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from '../../components/input'
import eye from '../../assets/images/eye.jpg'

import SwitchYes from '../../assets/images/YES.png'
import SwitchNo from '../../assets/images/NO.png'

import { Link } from 'react-router-dom';
import Button from '../../components/button'
import facebook from '../../assets/images/fb.png'
import google from '../../assets/images/google.png'
import apple from '../../assets/images/apple.png'
import colors from '../../themes/Colors';

import checklistborder from '../../assets/images/checklistborder.png'

import PopUpBox from '../../components/modal'



export class pwdConfirmationAddress extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
         
        Switch: SwitchNo,
          show: false,
        }
      }
    handleShow = (e)=> {
    
     this.setState({show: true})
    }
    handleClose = (e)=> {
    
        this.setState({show: false})
    }  
 changeSwitch=()=>{
    if(this.state.Switch== SwitchNo ){
      this.setState({Switch: SwitchYes})
   }else{ 
       this.setState({Switch: SwitchNo})
   }
  }
    
  render() {
      const {Switch} = this.state
    return (
      <>
        <StyledCG>
            <Header/>
            <h2>Person with Dementia Residing Address</h2>
            <Container>
                <Row>
                <Col>
                    <label>Same as Organisation Staff?</label>
                </Col>
                <Col>
                    <button  onClick={this.changeSwitch}>
                            <img src={Switch} />
                    </button>
                </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <label>Street Name</label>
                    </Col>
                    <Col>
                    <Input containerStyle="InputStyle" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Unit Number</label>
                    </Col>
                    <Col>
                    <Input containerStyle="InputStyle"/>
                   
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Postal Code</label>
                    </Col>
                    <Col>
                    <Input containerStyle="InputStyle" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>City,Country</label>
                    </Col>
                    <Col>
                    <Input containerStyle="InputStyle" />
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <p>* By clicking next, you consent to ADA collecting your information for the delivery of service. 
                        For more details on our terms of use and privacy policy, please click here.</p>

                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                       <Row> <button style={{marginRight:'10px',height:'fit-content'}} onClick><img src={checklistborder}/></button><p>I agree to the terms of use.</p>
                       </Row>
                    </Col>
                </Row>
                
               
                
            <StyledButton>
            <Row>
            <Col>
                <Button  backgroundColor={colors.color3} textColor={colors.color11} bordered={colors.color11} text="Back" />
                </Col>
                <Col>
                <Button text="Next" onClick={this.handleShow}/>
                <PopUpBox  show={this.state.show}  handleClose={this.handleClose}/>
              
                </Col></Row>
            </StyledButton>
            </Container>
            </StyledCG>

        
       
      </>
    )
  }
}


export default pwdConfirmationAddress